<template>
  <table class="table table-google">
    <thead>
      <tr>
        <th scope="col">Field</th>
        <th scope="col">Value</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Name</td>
        <td>
          <b-form-input
            v-model="label.label_name"
            size="sm"
            autocomplete="off"
            autofocus
          ></b-form-input>
        </td>
      </tr>
      <tr>
        <td>Label size</td>
        <td>
          <button-select
            v-model="label.label_type"
            :options="options.label_type"
            size="sm"
          >
          </button-select>
        </td>
      </tr>
      <tr>
        <td>Orientation</td>
        <td>
          <button-select
            v-model="label.portrait_mode"
            :options="options.portrait_mode"
            size="sm"
          >
          </button-select>
        </td>
      </tr>
      <tr>
        <td>Align content</td>
        <td>
          <button-select
            v-model="label.align_content"
            :options="options.align_content"
            size="sm"
          >
          </button-select>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');

export default {
  name: 'PropertiesForm',
  components: {
    ButtonSelect,
  },
  props: {
    label: Object,
  },
  data() {
    return {
      options: {
        label_type: [
          { text: 'SLP-SRL', value: 2 },
          { text: 'SLP-STAMP2', value: 33 },
        ],
        portrait_mode: [
          { text: 'Portrait', value: true },
          { text: 'Landscape', value: false },
        ],
        align_content: [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
